.tabs {
    display: flex;
  }
  
  .tab {
    padding: 10px;
    margin-right: 10px;
    border: 1px solid #ccc;
    cursor: pointer;
  }
  
  /* Add styling for the active tab */
  .active-tab {
    background-color: green;
    color: white;
  }