.main-section {
  display: flex;
  width: 100%;
  height: 100vh;
}

.section-left {
  background-color: #ffffff;
  width: 22%;
}

.section-right {
  background-color: #f7f7f2;
  width: 78%;
  padding-left: 2%;
  padding-right: 2%;
  padding-top: 3%;
}

.path-section {
  height: 10%;
}

.child-section {
  height: 88%;
}

.caterogyPageScroll {
  height: calc(100vh - 335px);
}

.caterogyPageScrolls {
  height: calc(100vh - 600px);
}

.categoryImageDiv {
  height: 50px !important;
  width: 50px !important;
  background-color: #f1f9f3 !important;
}

.categoryImage {
  object-fit: cover !important;
}

.deliveredSuccess {
  background: #29ae13;
  padding: 5px 15px;
  border-radius: 15px;
}

.bulkuploadbutton1 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px;
  border-radius: 6px 0px 0px 6px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
  display: flex;
  align-items: center;
  text-align: center;
  font-feature-settings: "ss01" on;
  width: 104px;
  height: 40px;
  background: #ffffff;
  color: black;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
}

.bulkuploadbutton2 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px;
  background: #ffffff;
  border-radius: 0px 6px 6px 0px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
  display: flex;
  align-items: center;
  text-align: center;
  font-feature-settings: "ss01" on;
  width: 104px;
  height: 40px;
  color: black;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
}

.actives {
  color: #febf22;
  background: #12352f;
}

.orderdetails-header {
  margin: 0;
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 170%;
  /* or 24px */

  color: #3d3d3d;
}

.orderdetails-info {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 170%;
  /* or 24px */

  color: #3d3d3d;
}

.edit {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 170%;
  /* or 20px */

  text-decoration-line: underline;
  text-transform: uppercase;

  color: #525252;
}

.dropdownoption {
  position: absolute;
  width: max-content;
  height: max-content;
  background: #ffffff;
  margin: 8px;
  padding: 8px;
  z-index: 1;
  /* top: 250px;
  right: 20px; */
  box-shadow: -1px 3px 10px rgba(83, 82, 82, 0.13);
}

.dropdownoptions {
  position: absolute;
  width: max-content !important;
  background: #ffffff;
  margin: 8px;
  padding: 8px;
  z-index: 1;
  /* top: 250px;
  right: 20px; */
  box-shadow: -1px 3px 10px rgba(83, 82, 82, 0.13);
}

.dropdownselectoption {
  width: max-content;
  height: max-content;
  margin-top: 10px;
  padding: 5px;
  border-bottom: 2px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 15px;
  text-align: center;
  text-transform: capitalize;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #3d3d3d;
  gap: 6px;
}

.dropdownselectbutton {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 6px;
}

.orderidlink {
  text-transform: uppercase;
}

.datepicker-value {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 12px;
  width: 150px;
  height: 36px;
  border: 0.8px solid #8F8F8F;
  border-radius: 4px;
  color: #3d3d3d;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 22%;
}

@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap');

/* invoice */

.invoiceCompanyName {
  margin: 0;
  padding: 0;
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  font-feature-settings: 'ss02' on, 'ss04' on;
  color: #000000;
}

.invoiceComppanyid {
  margin: 2.5px;
  padding: 0;
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  font-feature-settings: 'ss02' on, 'ss04' on;
  color: #000000;

}

.invoicelogo {
  width: 149.16px;
  height: 61.24px;
}

.invoiceOrderHeading {
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 21px;
  font-feature-settings: 'ss02' on, 'ss04' on;
  color: #000000;
  flex: none;
  order: 0;
  flex-grow: 0;
}

.invoicetableheading {
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 7px;
  line-height: 9px;
  text-transform: uppercase;
  font-feature-settings: 'ss02' on, 'ss04' on;

  color: #000000;


  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
}

.invoicetabledetails {
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 9px;
  line-height: 12px;
  /* identical to box height */

  font-feature-settings: 'ss02' on, 'ss04' on;

  color: #000000;


  /* Inside auto layout */

  flex: none;
  order: 1;
  flex-grow: 0;
}

.invoicePageScroll {
  height: calc(100vh - 25px);

}

.invoicebottominfo {
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 8px;
  line-height: 10px;
  margin: 0;
  padding: 0;
  color: #000000;


  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
}

.orderstabledetails {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */


  color: #3D3D3D;


  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
}

.orderstableheading {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 15px;
  /* identical to box height */

  text-transform: uppercase;

  color: #3D3D3D;


  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
}


.updateQtyHeading {
  width: 169px;
  height: 24px;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;

  color: #000000;


  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
}

.updatedqtyimg {
  width: 82px;
  height: 82px;
  left: 36px;
  top: 0px;

  background: #F1F9F3;
  border-radius: 9.37143px;
}

.updatedqtyproductname {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */


  color: #3D3D3D;


  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
}

.updatedqtybutton {
  box-sizing: border-box;

  /* Auto layout */

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 6px;

  width: 24px;
  height: 24px;
  background-color: white;
  border: 0.603053px solid #1C1B1F;
  border-radius: 3.61832px;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
}

.updatedtytotal {


  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */

  font-feature-settings: 'ss01' on;

  color: #3D3D3D;


  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 4px;
  gap: 10px;

  /* height: 25px; */

  background: #FFE094;
  border-radius: 6px;

  /* Inside auto layout */

  flex: none;
  order: 1;
  flex-grow: 0;
}
td:first-child {
  border-left-style: solid;
  border-top-left-radius: 10px; 
  border-bottom-left-radius: 10px;
}
td:last-child {
  border-right-style: solid;
  border-bottom-right-radius: 10px; 
  border-top-right-radius: 10px; 
}


.pagination-container {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  /* If you want some spacing between the pagination and other content at the bottom, you can use margin-bottom here */
  /* margin-bottom: 20px; */
}



