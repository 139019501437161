.imge{
    width: 154px;
    height: 158px;   
}
.box1{
 
    width: 123px;
    height: 48px;
}
.box2{
    width: 149px;
    height: 48px;
}
.box3{
    width: 174px;
    height: 48px;
}
.box4{
    width: 99px;
    height: 48px;
}
.horriLine{
    border-left: 1px solid #E0E0E0;
    height: 450px;
    left: 45%;
    margin-left: 8px;
    top: 16px;
    padding: 5px;
}
.box5{
    width: 295px;
    height: 127px;
}
.box6{
    width: 114px;
    height: 48px;
}
.box7{
    width: 241px;
    height: 48px;
}
.box8{
    width: 161px;
    height: 48px;
}
.box9{
    width: 107px;
    height: 48px;
}
.last{
    justify-content: space-between;
    display: flex;
}
.size1{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 150%;
    color: #000000;
}
.size2{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 150%;
}
.color1{
    color: #3D3D3D;
}
.color2{
    color: #525252;
}
.addr{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 160%;
    color: #525252;
}
.underli{
    text-decoration-line: underline;
}
.Edits{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 112.52%;
    color: rgba(61, 61, 61, 0.75);
    width: 26px;
    height: 16px;  
}
.rowhi{
   height: 70px;
}
