.img-boxx{
    /* height: 392px; */
    width: 310px;
    background-color: #F7F7F2;
}
.addrressize{
    width: 295px;
    height: 127px;
}
.dark-name{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    color: #000000;
}
.fant-name{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 150%;
    color: #525252;
}
.addrs-styles{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 160%;
    color: #525252;
}
.ims-centerstyles{
    margin: auto;
    /* width: 173%; */
    padding: 11%;
    margin-top: 0px
}
.vartical-1st {
  border-left: 0.81px solid #E0E0E0;
  height: 500px;
  position: relative;
  left: 6%;
  margin-left: -3px;
  top: 60px;
}
.vartical-2nd {
  border-left: 0.81px solid #E0E0E0;
  height: 500px;
  position: relative;
  left: 2%;
  margin-left: -3px;
  top: 62px;
}
.margin-adusting{
    margin-left: 2%;
}
#rowshightss{
    height: 245px;
}
.profile-image{
    width: 100%;
}
.nav-link{cursor: pointer;}
.nav-link.activev {
    border-bottom: 5px solid #12352F !important;
    font-weight: 700;
    transition: all ease-in 50ms;
    
  }



@media  only screen and (max-width:600px){
    .vartical-1st{
        display: none;
    }
    .vartical-2nd{
        display: none;
    }
    .email-details{
        margin: 0 0px !important;
    }
}
