.Aadharredus{
    border: 1px solid #E0E0E0;
    border-radius: 6px;
    width: 75%;
}
.height-unicno{
    height: 71px;
    width: 129px;
}
.city-margin{
    margin-left: -70px;
}
.btn-saves{
    width: 212px;
    height: 48px;
    background: #12352F;
    border: 1px solid #12352F;
    border-radius: 6px;
    color: #FEBF22;
}
.font-deatils{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #3D3D3D;
}
.mainfont-styl{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 150%;
    color: #3D3D3D;
}
.mobilno-style{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 150%;
    text-decoration-line: underline;
    color: #3D3D3D;
}
.small-style{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 160%;
    color: #525252;
}