.sizey {

    height: 500px;

    background: #FFFFFF;
    border-radius: 6px;
}

.colbox {


    width: 350px;
    height: 170px;
    border: 2px solid grey;

}
.btn_bg{background-color: #12352f !important} 

.text-rev {

    width: 250px;
    height: 15px;

    margin-top: 5px;

    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 12px;

    /* identical to box height */

    text-transform: capitalize;

    color: #3D3D3D;


}

.text2 {
    width: 56px;
    height: 19px;
    font-family: 'Inter';
    font-weight: 700;
    font-size: 16px;
    text-transform: capitalize;

    color: #12352F;
    margin-top: 5px;
    margin-bottom: 5px;

}

.green {

    width: 200px;
    height: 17px;
    margin-top: 30px;
    margin-bottom: 15px;
    font-family: 'Inter';
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    /* identical to box height */

    font-feature-settings: 'ss01'on;
    font-style: normal;
    color: #05B171;
    overflow: hidden;
    white-space: nowrap;

}
.red{

    width: 200px;
    height: 17px;
    margin-top: 30px;
    margin-bottom: 15px;
    font-family: 'Inter';
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    /* identical to box height */

    font-feature-settings: 'ss01'on;
    font-style: normal;
    color: #b10505;
    overflow: hidden;
    white-space: nowrap;

}

.perc {

    width: 90px;
    height: 39px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 32px;
    line-height: 39px;
    /* identical to box height */
    text-transform: capitalize;
    color: #05B171;
    margin-top: 62px;
}

.bottom {

    align-self: flex-end;
    margin-top: 40px;
}

.topcont {
    margin-top: 20px;
}


.mlleft {

    margin-left: -50px;
}

.mlright {
    margin-left: 20px;
}
.List_mobile_display{
    display: none;
    }


@media  only screen and (max-width:600px){
    .offset-1{
        margin-left: 0 !important;
    }
    .List_mobile_display{
        display: block;

    }
    .List_mobile_title{
        display: none;
    }
}



