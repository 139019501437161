.main-section {
    display: flex;
    width: 100%;
    height: 100vh;
}

.section-left {
    background-color: #ffffff;
    width: 22%;
}

.section-right {
    background-color: #f7f7f2;
    width: 78%;
    padding-left: 2%;
    padding-right: 2%;
    padding-top: 3%;
}

.path-section {
    height: 10%;
}

.child-section {
    height: 88%;
}

.caterogyPageScroll {
    height: calc(100vh - 335px);
    overflow-y: auto;
    overflow-x: hidden;
}

.categoryImageDiv {
    height: 50px !important;
    width: 50px !important;
    background-color: #F1F9F3 !important;
}

.categoryImage {
    object-fit: cover !important;
}

.sub-menus {
    width: 1038px;
    height: 68px;
    background: red;
    border-radius: 8px;
}


.text-menu2 {
    margin-left: 94px;
}



.icon-rtl {
    width: 212px;
    height: 36px;
    border: 0.8px solid #8F8F8F;
    border-radius: 4px;
    padding-right: 25px;
    background: url("https://static.thenounproject.com/png/101791-200.png") no-repeat right;
    background-size: 20px;
}

.img-search {

    width: 16px;
    height: 16px;
}
.has-error {
    border: 1px solid red;
  }
  
  .error {
    color: red;
  }

.top {
    width: 1038px;
    height: 84px;
    background: #FFFFFF;
    border-radius: 8px;
}


.btn_bg{background-color: #12352f !important} 

.inputwo {
    box-sizing: border-box;

    width: 212px;
    height: 36px;


    border: 0.8px solid #8F8F8F;
    border-radius: 4px;
}

.sidebar {
    width: 1038px;
    height: 68px;
    background: #FFFFFF;
    border-radius: 8px;

}

.mainbox {
    width: 1440px;
    height: 846px;

    background: #F7F7F2;
}

.boxy {
    width: 1038px;
    height: 68px;
    background: #FFFFFF;
    border-radius: 8px;
    margin-bottom: 16px;
}

.btco {
    box-sizing: border-box;

    /* Auto layout */

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px 16px;
    gap: 8px;
    width: 75px;
    height: 30px;


    border: 1px solid #12352F;
    border-radius: 6px;
}

.boxsm {
    box-sizing: border-box;

    width: 102px;
    height: 36px;


    border: 0.8px solid #8F8F8F;
    border-radius: 4px;
}

.searchby {
    box-sizing: border-box;

    width: 212px;
    height: 36px;


    border: 0.8px solid #8F8F8F;
    border-radius: 4px;
    padding-left: 15px;
}

.mainboxs {
    width: 1440px;
    height: 846px;

    background: #F7F7F2;

}

.rds {
    background: #F7F7F2;
}

@media  only screen and (max-width:600px){
    .user-mobile{
        flex-direction: column;
    }
    .user-select{
        width: 100% !important;
    }
}