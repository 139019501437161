.topbox {
    width: 1038px;
    height: 84px;
    margin-top: 104px;
    margin-left: 378px;
    box-sizing: border-box;
    background-color: white;
}

.free {

    width: 58px;
    height: 17px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    /* identical to box height */

    font-feature-settings: 'ss01'on;

    color: #3D3D3D;

}

.mech {

    border: 0.8px solid #E0E0E0;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
}

.size {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    /* identical to box height */

    display: inline-block;
    overflow: hidden;
    word-wrap: break-word;
}

.uli {
    text-decoration-line: underline;
    color: #3D3D3D;

}

.tgt {
    width: 155px;
    height: 32px;
}

.ulx {
    text-decoration-line: underline;
    line-height: 17px;
    /* identical to box height */

    text-decoration-line: underline;
    color: #3D3D3D;


}

.desin {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    /* identical to box height */
    color: #3D3D3D;
    width: 58px;
    height: 17px;

}

.btn-link {
    cursor: pointer;
}

.addbtn {
    box-sizing: border-box;
    /* Auto layout */
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px 16px;
    gap: 8px;
    width: 79px;
    height: 30px;
    border: 1px solid #12352F;
    border-radius: 6px;
}