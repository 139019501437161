.horriLiness{
    border-left: 1px solid #E0E0E0;
    height: 532px;
    left: 22%; 
}

.fntdark{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 150%;
    color: #000000;
}

.fntfant{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 150%;
    color: #525252;
}
.feedbtn{
    width: 305px;
    height: 41px;
    left: 747px;
    top: 604.5px;

    background: #12352F;
    border-radius: 6px;

    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    font-feature-settings: 'ss01' on;
    color: #FEBF22;

}
.boox{
    height: 192px;
    width: 448px;
}
.boox2{
    height: 72px;
    width: 228px;
}
.underli{
    text-decoration-line: underline;
}
.fnyclss{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 150%;
    color: #000000;
}
.imgvect{
    width: 17.66px;
    height: 16.13px;
}